export class RetiroFilter {
  from: String;
  to: String;
  sucursal?: number;
  almacen?: number;
  cliente?: number;
  vendedor?: number;
  folioSucursal: String;
  folio: String;
}
