import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

// import { AppSettings } from '../../models/app-settings';
import { UsersService } from '../users/users.service';
import { AppConfig } from '../appConfig/app.config';
import { RetiroFilter } from '../../models/RetiroFilter';

@Injectable({
  providedIn: 'root'
})
export class RetirosService {

  // private _url = `${AppSettings.API_ENDPOINT}/Retiros`;
  private _url = `${AppConfig.settings.API_ENDPOINT}/Retiros`;
  private _getLista = `${this._url}/Lista`;
  private _getGetRetiro = `${this._url}/GetRetiro`;
  private _procesar = `${this._url}/Procesar`;
  private _getCombos = `${this._url}/ListasCombos`;
  private _getImprimir = `${this._url}/Imprimir`;

  constructor(public _http: HttpClient, private _userService: UsersService) {
    this._userService.loadStorage();
  }

  getLista(from: String, to: String, sucursal: number, almacen: number, cliente: number, vendedor: number, folioSucursal: String, folio: String): Observable<any[]> {
    // const params = new HttpParams()
    //   .set('from', from.toString())
    //   .set('to', to.toString())
    //   .set('sucursal', String(sucursal))
    //   .set('almacen', String(almacen))
    //   .set('cliente', String(cliente))
    //   .set('vendedor', String(vendedor))
    //   .set('folioSucursal', String(folioSucursal))
    //   .set('folio', String(folio));

    let filterModel = new RetiroFilter();
      filterModel.from = from;
      filterModel.to = to;
      filterModel.sucursal = sucursal;
      filterModel.almacen = almacen;
      filterModel.cliente = cliente;
      filterModel.vendedor = vendedor;
      filterModel.folioSucursal = folioSucursal;
      filterModel.folio = folio;

    return this._http.put<any[]>(this._getLista, filterModel,  { params: null, headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  getCombos(): Observable<any> {
    return this._http.get<any>(this._getCombos, { params: null, headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  getRetiro(id: number): Observable<any>  {
    return this._http.get<any>(`${this._getGetRetiro}/${id}`, { headers: this._userService.header})
      .pipe(
        tap(data => data),
        catchError(this.handleError)
      );
  }

  procesar(model: any): Observable<any> {
    return this._http.post<any>(`${this._procesar}`, model, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  imprimir(model) {
    return  this._http.post(`${this._getImprimir}`, model, {responseType: 'arraybuffer', headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  // Handdle Error methor for observale
  private handleError(err: HttpErrorResponse) {
    return throwError(err.error);
  }
}
