import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SidebarComponent } from './sidebar/sidebar.component';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { NopagefoundComponent } from './nopagefound/nopagefound.component';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgOptionHighlightModule } from '@ng-select/ng-option-highlight';
import { NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [SidebarComponent, HeaderComponent, FooterComponent, NopagefoundComponent],
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    NgSelectModule,
    NgOptionHighlightModule,
    NgbDatepickerModule
  ],
  exports: [SidebarComponent, HeaderComponent, FooterComponent, NopagefoundComponent]
})
export class SharedModule { }
