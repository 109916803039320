import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

// import { AppSettings } from '../../models/app-settings';
import { UsersService } from '../users/users.service';
import { AppConfig } from '../appConfig/app.config';

@Injectable({
  providedIn: 'root'
})
export class ExistenciasService {

  // private _url = `${AppSettings.API_ENDPOINT}/Existencias`;
  private _url = `${AppConfig.settings.API_ENDPOINT}/Existencias`;
  private _getGetExistencia = `${this._url}/GetExistencia`;
  private _getCombos = `${this._url}/ListasCombos`;
  private _eliminarKardex = `${this._url}/EliminarKardex`;
  private _inventarioInicialKardex = `${this._url}/InventarioInicialKardex`;
  private _regenerarKardexProducto = `${this._url}/RegenerarKardexProducto`;
  private _regenerarKardexGeneral = `${this._url}/RegenerarKardexGeneral`;
  private _dashboard = `${this._url}/Dashboard`;

  constructor(public _http: HttpClient, private _userService: UsersService) {
    this._userService.loadStorage();
  }

  getExistencia(model: any): Observable<any> {
    return this._http.post<any>(`${this._getGetExistencia}`, model, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  getCombos(): Observable<any> {
    return this._http.get<any>(this._getCombos, { params: null, headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  eliminarKardex(): Observable<any> {
    return this._http.post<any>(`${this._eliminarKardex}`, null, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  inventarioInicialKardex(model: any): Observable<any> {
    return this._http.post<any>(`${this._inventarioInicialKardex}`, model, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  regenerarKardexProducto(model: any): Observable<any> {
    return this._http.post<any>(`${this._regenerarKardexProducto}`, model, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  regenerarKardexGeneral(model: any): Observable<any> {
    return this._http.post<any>(`${this._regenerarKardexGeneral}`, model, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  getDashboard(): Observable<any> {
    return this._http.get<any>(this._dashboard, { params: null, headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  // Handdle Error methor for observale
  private handleError(err: HttpErrorResponse) {
    return throwError(err.error);
  }
}
