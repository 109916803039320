import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Login } from '../models/Login';
import { UsersService } from '../services/service.index';
import { AppConfig } from '../services/appConfig/app.config';
import { Title } from '@angular/platform-browser';

declare function init_plugins();

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit, AfterViewInit {

  user: Login = new Login();
  isSing = false;

  Toast = Swal.mixin({
    toast: true,
    position: 'top-end',
    showConfirmButton: false,
    timer: 3000
  });

  Company: string;
  Company_Lg_Pre1: string;
  Company_Lg_Pre2: string;

  constructor(private _userService: UsersService, public router: Router, private titleService: Title) { 

    this.Company_Lg_Pre1 = AppConfig.settings.Company_Lg_Pre1;
    this.Company_Lg_Pre2 = AppConfig.settings.Company_Lg_Pre2;
    this.titleService.setTitle(AppConfig.settings.Company);
  }

  ngOnInit() {
  }

  onSubmit() {
    this.isSing = true;
    this._userService.login(this.user)
    .subscribe(
      success => {
        this.isSing = false;
        this.Toast.fire({
          type: 'success',
          title: 'Inició Sesión Exitosamente'
        });
        this.router.navigate(['/dashboard']);
      },
      error => {
        this.isSing = false;
        Swal.fire('Error!', 'Error al iniciar sesion' , 'error');
      });
  }

  ngAfterViewInit(): void {
    init_plugins();
  }
}
