import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

// import { AppSettings } from '../../models/app-settings';
import { UsersService } from '../users/users.service';
import { AppConfig } from '../appConfig/app.config';

@Injectable({
  providedIn: 'root'
})
export class UnidadMedidaService {

  // private _url = `${AppSettings.API_ENDPOINT}/UnidadMedida`;
  private _url = `${AppConfig.settings.API_ENDPOINT}/UnidadMedida`;
  private _getLista = `${this._url}/Lista`;
  private _getGetUnidadMedida = `${this._url}/GetUnidadMedida`;
  private _guardar = `${this._url}/Guardar`;
  private _eliminar = `${this._url}/Eliminar`;

  constructor(public _http: HttpClient, private _userService: UsersService) {
    this._userService.loadStorage();
  }

  getLista(nombre: String, abreviacion: String, clavesat: String, nombresat: String, descripcionsat: String): Observable<any[]> {
    const params = new HttpParams()
      .set('nombre', nombre.toString())
      .set('abreviacion', abreviacion.toString())
      .set('clavesat', clavesat.toString())
      .set('nombresat', nombresat.toString())
      .set('descripcionsat', descripcionsat.toString());

    return this._http.get<any[]>(this._getLista, { params: params, headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  getUnidadMedida(id: number): Observable<any>  {
    return this._http.get<any>(`${this._getGetUnidadMedida}/${id}`, { headers: this._userService.header})
      .pipe(
        tap(data => data),
        catchError(this.handleError)
      );
  }

  guardar(model: any): Observable<any> {
    return this._http.post<any>(`${this._guardar}`, model, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  eliminar(id: number) {
      return this._http.delete(`${this._eliminar}/${id}`, { headers: this._userService.header}).pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  // Handdle Error methor for observale
  private handleError(err: HttpErrorResponse) {
    return throwError(err.error);
  }
}
