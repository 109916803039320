import { Component, OnInit } from '@angular/core';
import { UsersService } from '../../services/users/users.service';
import { AclService } from 'ng2-acl/dist';
import { AppConfig } from '../../services/appConfig/app.config';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  Logo_Image: string;
  constructor(private _userService: UsersService, public aclService: AclService) {
    this._userService.loadStorage();
    this.Logo_Image = AppConfig.settings.Logo_Image;
  }

  ngOnInit() {
  }

}
