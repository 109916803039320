import { Component, OnInit } from '@angular/core';
import { AppConfig } from '../../services/appConfig/app.config';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  Company = '';
  constructor() {
    this.Company = AppConfig.settings.Company;
  }
  
  ngOnInit() {
  }

}
