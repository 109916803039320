import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';

import {
  UsersService,
  LoginGuardGuard,
  TiposUsuarioService,
  SucursalesService,
  AlmacenesService,
  ClientesService,
  ProveedoresService,
  LineaproveedorService,
  UnidadMedidaService,
  LineasService,
  SubLineaService,
  ModelosService,
  ProductosService,
  CotizacionesService,
  SolicitudAlmacenService,
  OrdenCompraService,
  CompraService,
  VentasService,
  FisicoService,
  TipoEntradaSalidaService,
  EntradaService,
  SalidaService,
  TraspasosService,
  ComodatosService,
  RetirosService,
  DescomposicionService,
  ExistenciasService,
  ReportesService
 } from './service.index';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    UsersService,
    LoginGuardGuard,
    TiposUsuarioService,
    SucursalesService,
    AlmacenesService,
    ClientesService,
    ProveedoresService,
    LineaproveedorService,
    UnidadMedidaService,
    LineasService,
    SubLineaService,
    ModelosService,
    ProductosService,
    CotizacionesService,
    SolicitudAlmacenService,
    OrdenCompraService,
    CompraService,
    VentasService,
    FisicoService,
    TipoEntradaSalidaService,
    EntradaService,
    SalidaService,
    TraspasosService,
    ComodatosService,
    RetirosService,
    DescomposicionService,
    ExistenciasService,
    ReportesService
  ]
})
export class ServiceModule { }
