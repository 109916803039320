import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { catchError, map, tap } from 'rxjs/operators';
import { Observable, throwError } from 'rxjs';

// import { AppSettings } from '../../models/app-settings';
import { UsersService } from '../users/users.service';
import { AppConfig } from '../appConfig/app.config';
import { ComodatoFilter } from '../../models/ComodatoFilter';

@Injectable({
  providedIn: 'root'
})
export class ComodatosService {

  // private _url = `${AppSettings.API_ENDPOINT}/Comodatos`;
  // private _urlRetiro = `${AppSettings.API_ENDPOINT}/Retiros`;
  private _url = `${AppConfig.settings.API_ENDPOINT}/Comodatos`;
  private _urlRetiro = `${AppConfig.settings.API_ENDPOINT}/Retiros`;
  private _getLista = `${this._url}/Lista`;
  private _getGetRemision = `${this._url}/GetRemision`;
  private _getGetCustomRemision = `${this._url}/GetCustomRemision`;
  private _guardar = `${this._url}/Guardar`;
  private _guardarCustomRemision = `${this._url}/GuardarCustomRemision`;
  private _procesar = `${this._url}/Procesar`;
  private _cancelar = `${this._url}/Cancelar`;
  private _eliminar = `${this._url}/Eliminar`;
  private _getDireccionDefault = `${this._url}/DireccionDefault`;
  private _getDirecciones = `${this._url}/Direcciones`;
  private _getCombos = `${this._url}/ListasCombos`;
  private _getGetProductosKit = `${this._url}/GetProductosKit`;
  private _getGetRemisionesCliente = `${this._url}/GetRemisionesCliente`;
  private _guardarVenta = `${this._url}/GuardarVenta`;
  private _guardarDevolucion = `${this._url}/GuardarDevolucion`;
  private _getImprimir = `${this._url}/Imprimir`;
  private _getImprimirRetiro = `${this._urlRetiro}/Imprimir`;
  private _getGetRetirosVentas = `${this._url}/GetRetirosVentas`;

  constructor(public _http: HttpClient, private _userService: UsersService) {
    this._userService.loadStorage();
  }

  getLista(from: String, to: String, sucursal: number, almacen: number, cliente: number, vendedor: number, folioSucursal: String, folio: String): Observable<any[]> {
    // const params = new HttpParams()
    //   .set('from', from.toString())
    //   .set('to', to.toString())
    //   .set('sucursal', String(sucursal))
    //   .set('almacen', String(almacen))
    //   .set('cliente', String(cliente))
    //   .set('vendedor', String(vendedor))
    //   .set('folioSucursal', String(folioSucursal))
    //   .set('folio', String(folio));

    let filterModel = new ComodatoFilter();
      filterModel.from = from;
      filterModel.to = to;
      filterModel.sucursal = sucursal;
      filterModel.almacen = almacen;
      filterModel.cliente = cliente;
      filterModel.vendedor = vendedor;
      filterModel.folioSucursal = folioSucursal;
      filterModel.folio = folio;

    return this._http.put<any[]>(this._getLista, filterModel, { params: null, headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  getCombos(): Observable<any> {
    return this._http.get<any>(this._getCombos, { params: null, headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  getRemision(id: number): Observable<any>  {
    return this._http.get<any>(`${this._getGetRemision}/${id}`, { headers: this._userService.header})
      .pipe(
        tap(data => data),
        catchError(this.handleError)
      );
  }

  getRemisionCustom(id: number): Observable<any>  {
    return this._http.get<any>(`${this._getGetCustomRemision}/${id}`, { headers: this._userService.header})
      .pipe(
        tap(data => data),
        catchError(this.handleError)
      );
  }

  guardar(model: any): Observable<any> {
    return this._http.post<any>(`${this._guardar}`, model, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  guardarCambiosComodato(model: any): Observable<any> {
    return this._http.post<any>(`${this._guardarCustomRemision}`, model, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  procesar(model: any): Observable<any> {
    return this._http.post<any>(`${this._procesar}`, model, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  cancelar(model: any): Observable<any> {
    return this._http.post<any>(`${this._cancelar}`, model, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  eliminar(model: any): Observable<any> {
    return this._http.post<any>(`${this._eliminar}`, model, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  getDireccionDefault(id: number): Observable<any>  {
    return this._http.get<any[]>(`${this._getDireccionDefault}/${id}`, { headers: this._userService.header})
      .pipe(
        tap(data => data),
        catchError(this.handleError)
      );
  }

  getDirecciones(id: number): Observable<any>  {
    return this._http.get<any[]>(`${this._getDirecciones}/${id}`, { headers: this._userService.header})
      .pipe(
        tap(data => data),
        catchError(this.handleError)
      );
  }

  getProductos(id: number): Observable<any>  {
    return this._http.get<any>(`${this._getGetProductosKit}/${id}`, { headers: this._userService.header})
      .pipe(
        tap(data => data),
        catchError(this.handleError)
      );
  }

  getRemisionesCliente(id: number): Observable<any>  {
    return this._http.get<any>(`${this._getGetRemisionesCliente}/${id}`, { headers: this._userService.header})
      .pipe(
        tap(data => data),
        catchError(this.handleError)
      );
  }

  guardarVenta(model: any): Observable<any> {
    return this._http.post<any>(`${this._guardarVenta}`, model, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  guardarDevolucion(model: any): Observable<any> {
    return this._http.post<any>(`${this._guardarDevolucion}`, model, { headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  imprimir(model) {
    return  this._http.post(`${this._getImprimir}`, model, {responseType: 'arraybuffer', headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  imprimirRetiro(model) {
    return  this._http.post(`${this._getImprimirRetiro}`, model, {responseType: 'arraybuffer', headers: this._userService.header})
    .pipe(
      tap(data => data),
      catchError(this.handleError)
    );
  }

  getRetirosVentas(id: number): Observable<any>  {
    return this._http.get<any>(`${this._getGetRetirosVentas}/${id}`, { headers: this._userService.header})
      .pipe(
        tap(data => data),
        catchError(this.handleError)
      );
  }

  // Handdle Error methor for observale
  private handleError(err: HttpErrorResponse) {
    return throwError(err.error);
  }
}
